import React, { FC } from "react";
import clsx from "clsx";

import {
  MDX,
  MDXProps,
  WrapperPrimitive as Wrapper,
  WrapperProps,
} from "components";

export type MainPattern = "content" | "wrapper";

export interface LayoutMainProps extends Omit<MDXProps | WrapperProps, "is"> {
  is?: MainPattern;
  isMDX?: boolean;
}

export const LayoutMain: FC<LayoutMainProps> = ({
  as = "main",
  is = "wrapper",
  children,
  className,
  isMDX,
  ...rest
}) => {
  if (isMDX) {
    return (
      <MDX
        as={as}
        {...(rest as MDXProps)}
        className={clsx("flex-1 bg-transparent", className)}
      >
        {children}
      </MDX>
    );
  }

  return (
    <Wrapper
      as={as}
      {...(rest as WrapperProps)}
      className={clsx("flex-1 bg-transparent", className)}
    >
      {children}
    </Wrapper>
  );
};
