import React, { FC } from "react";
import clsx from "clsx";

import { Link, Section, SectionProps, Text } from "components";
import {
  Copyright,
  CopyrightProps,
  FooterMenu,
  FooterMenuProps,
} from "containers";
import { GatsbyLocation } from "types";

export type LayoutFooterPattern = "page";

export interface LayoutFooterProps extends Omit<SectionProps, "is"> {
  activeClassName?: string;
  copyright?: CopyrightProps;
  isHidden?: boolean;
  is?: LayoutFooterPattern;
  location?: GatsbyLocation;
  menu?: FooterMenuProps;
  showCopyright?: boolean;
  showLegalDisclaimer?: boolean;
  showMenu?: boolean;
}

export const LayoutFooter: FC<LayoutFooterProps> = ({
  as = "footer",
  children,
  className,
  container,
  copyright,
  is = "page",
  isHidden = false,
  location,
  menu,
  showCopyright = true,
  showLegalDisclaimer = true,
  showMenu = true,
  ...rest
}) => {
  if (isHidden) return null;

  return (
    <Section
      as={as}
      is="navbar"
      color="contrast"
      {...(rest as SectionProps)}
      container={{
        is: "wide",
        ...container,
        className: clsx(
          "flex flex-col flex-nowrap md:flex-row md:flex-wrap",
          "content-between items-center md:items-end justify-between",
          container?.className
        ),
      }}
      className={clsx(
        "bg-secondary-contrast text-secondary-contrast",
        "items-end text-center md:text-left",
        is === "page" && "pt-12 md:pt-14 xl:pt-16",
        className
      )}
    >
      {showCopyright && (
        <Copyright {...copyright}>
          {showLegalDisclaimer && (
            <Text as="small" is="caption">
              Please read our{" "}
              <Link to="/legal-disclaimer" text="caption">
                legal disclaimer
              </Link>{" "}
              on how we work with architects and for copyright details.
            </Text>
          )}
        </Copyright>
      )}
      {children}
      {showMenu && <FooterMenu location={location} {...menu} />}
    </Section>
  );
};
