import React, { FC } from "react";
import clsx from "clsx";

import {
  BoxPrimitive as Box,
  BoxProps,
  Section,
  SectionProps,
  ValueCard,
} from "components";
import { ValueNode, useValuesQuery } from "graphql";

import { valuesCaption, valuesGrid, valuesSection } from "./Values.module.scss";

export interface ValuesProps extends SectionProps {
  grid?: BoxProps;
  values?: ValueNode[];
}

export const Values: FC<ValuesProps> = ({
  caption,
  children,
  className,
  container,
  grid,
  is = "feature",
  values,
  ...rest
}) => {
  const { nodes } = useValuesQuery();

  return (
    <Section
      className={clsx(valuesSection, className)}
      caption={{
        heading: "Our Values",
        ...caption,
        className: clsx(valuesCaption, caption?.className),
      }}
      container={container}
      {...(rest as SectionProps)}
    >
      {values?.length ? (
        <Box {...grid} className={clsx(valuesGrid, grid?.className)}>
          {values.map((value) => (
            <ValueCard key={value.id} {...value} />
          ))}
        </Box>
      ) : (
        nodes?.length && (
          <Box {...grid} className={clsx(valuesGrid, grid?.className)}>
            {nodes.map((value) => (
              <ValueCard key={value.id} {...value} />
            ))}
          </Box>
        )
      )}
      {children}
    </Section>
  );
};
