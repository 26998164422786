import React, { FC } from "react";
import clsx from "clsx";

import { Link, Section, SectionProps, Text } from "components";
import { ContactForm } from "containers";
import { useSiteMetadataQuery } from "graphql";

export interface ContactProps extends SectionProps {
  location?: any;
}

export const Contact: FC<ContactProps> = ({
  caption,
  children,
  className,
  container,
  location,
  ...rest
}) => {
  const { organization } = useSiteMetadataQuery();

  return (
    <Section
      as="footer"
      is="heel"
      id="contact"
      color="contrast"
      className={className}
      {...(rest as SectionProps)}
      caption={{
        heading: "Get in Touch",
        ...caption,
      }}
      container={{
        ...container,
        className: clsx("flex flex-col flex-nowrap", container?.className),
      }}
    >
      {organization?.email && (
        <Text as="h4">
          Email us:{" "}
          <Link to={`mailto:${organization.email}`} size="inherit">
            {organization.email}
          </Link>
        </Text>
      )}
      {/* {organization?.telephone && (
        <Text as="h4">
          Call us:{" "}
          <Link to={`tel:${organization.telephone}`} size="inherit">
            {organization.telephone}
          </Link>
        </Text>
      )} */}
      <Text as="h5">Or fill out the short form below.</Text>
      {children}
      <ContactForm location={location} />
    </Section>
  );
};
