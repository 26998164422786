import React, { FC } from "react";
import clsx from "clsx";

import {
  BoxPrimitive as Box,
  BoxProps,
  Section,
  SectionProps,
  ProcessCard,
} from "components";
import { ProcessNode, useProcessesQuery } from "graphql";

import { processesGrid } from "./Processes.module.scss";

export interface ProcessesProps extends SectionProps {
  grid?: BoxProps;
  processes?: ProcessNode[];
}

export const Processes: FC<ProcessesProps> = ({
  caption,
  children,
  className,
  container,
  grid,
  is = "feature",
  processes,
  ...rest
}) => {
  const { nodes } = useProcessesQuery();

  return (
    <Section
      id="process"
      color="paper"
      className={className}
      caption={{
        heading: "Our Process",
        ...caption,
      }}
      container={container}
      {...(rest as SectionProps)}
    >
      {processes?.length ? (
        <Box {...grid} className={clsx(processesGrid, grid?.className)}>
          {processes.map((process) => (
            <ProcessCard key={process.id} {...process} />
          ))}
        </Box>
      ) : (
        nodes?.length && (
          <Box {...grid} className={clsx(processesGrid, grid?.className)}>
            {nodes.map((process) => (
              <ProcessCard key={process.id} {...process} />
            ))}
          </Box>
        )
      )}
      {children}
    </Section>
  );
};
