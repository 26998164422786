import { useStaticQuery, graphql } from "gatsby";

import { TestimonyNode } from "./testimonies.gql";

export interface TestimonyData {
  testimony: TestimonyNode;
}

export const useFeaturedTestimonyQuery = () => {
  const { testimony }: TestimonyData = useStaticQuery(
    graphql`
      query FeaturedTestimonyQuery {
        testimony: testimoniesYaml(featured: { eq: true }) {
          client
          date
          id
          name
          quote
          title
        }
      }
    `
  );

  return testimony;
};
