import React, { FC } from "react";
import clsx from "clsx";

import { Hero, HeroProps, HeroPattern, Media } from "components";

export interface ProjectHeroProps extends Omit<HeroProps, "is"> {
  hero?: HeroPattern;
}

export const ProjectHero: FC<ProjectHeroProps> = ({
  caption,
  children,
  className,
  container,
  hero = "page",
  media,
  vh = "full",
  ...rest
}) => (
  <Hero
    is={hero}
    vh={vh}
    container={{
      ...container,
      className: clsx("justify-center", container?.className),
    }}
    caption={{
      ...caption,
      className: clsx(
        "flex flex-col flex-nowrap justify-center lg:pr-4 lg:max-w-screen-xs xl:max-w-screen-sm",
        caption?.className
      ),
      headingProps: {
        ...caption?.headingProps,
        className: clsx("max-w-screen-md", caption?.headingProps?.className),
      },
      subheadingProps: {
        ...caption?.subheadingProps,
        className: clsx(
          "border-t-2 border-solid border-paper mt-3 pt-3",
          caption?.subheadingProps?.className
        ),
      },
    }}
    {...(rest as HeroProps)}
    className={clsx("items-center", className)}
  >
    {media && (
      <Media
        {...media}
        className={clsx(
          "w-full lg:w-2/5 mt-8 lg:mt-0 lg:absolute lg:top-auto lg:bottom-0 lg:right-8 xl:right-16",
          media?.className
        )}
      />
    )}
    {children}
  </Hero>
);
