import React, { FC } from "react";
import clsx from "clsx";

import {
  BoxPrimitive as Box,
  ButtonLink,
  Card,
  Link,
  Media,
  Section,
  Text,
} from "components";
import { ServicesProps } from "containers";
import { useFeaturedServicesQuery } from "graphql";

import {
  featuredServices,
  featuredServicesButton,
  featuredServicesGrid,
  featuredServicesMedia,
} from "./FeaturedServices.module.scss";

export const FeaturedServices: FC<ServicesProps> = ({
  children,
  className,
  grid,
  services,
  ...rest
}) => {
  const { nodes } = useFeaturedServicesQuery();

  return (
    <Section
      mod="compact fluid"
      {...rest}
      className={clsx(featuredServices, className)}
    >
      {services?.length ? (
        <Box {...grid} className={clsx(featuredServicesGrid, grid?.className)}>
          {services.map(
            ({
              fields: { slug },
              frontmatter: { featuredDescription, featuredImage, title },
              id,
            }) => (
              <Card key={id}>
                <Text as="h4" is="subheading">
                  {title}
                </Text>
                <Link to={slug}>
                  <Media
                    className={featuredServicesMedia}
                    image={featuredImage}
                  />
                </Link>
                <Text>{featuredDescription}</Text>
                <ButtonLink
                  className={featuredServicesButton}
                  color="primary"
                  to={slug}
                >
                  See {title}
                </ButtonLink>
              </Card>
            )
          )}
        </Box>
      ) : (
        nodes?.length && (
          <Box
            {...grid}
            className={clsx(featuredServicesGrid, grid?.className)}
          >
            {nodes.map(
              ({
                fields: { slug },
                frontmatter: { featuredDescription, featuredImage, title },
                id,
              }) => (
                <Card key={id}>
                  <Text as="h4" is="subheading">
                    {title}
                  </Text>
                  <Link to={slug}>
                    <Media
                      className={featuredServicesMedia}
                      image={featuredImage}
                    />
                  </Link>
                  <Text>{featuredDescription}</Text>
                  <ButtonLink
                    className={featuredServicesButton}
                    color="primary"
                    to={slug}
                  >
                    See {title}
                  </ButtonLink>
                </Card>
              )
            )}
          </Box>
        )
      )}
      {children}
    </Section>
  );
};
