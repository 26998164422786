import { useStaticQuery, graphql } from "gatsby";

export type ExpertTitle =
  | "Contractors"
  | "Electrical Engineers"
  | "Mechanical Engineers"
  | "Plumbing Engineers"
  | "Structural Engineers"
  | string;

export type ExpertNode = {
  description?: string;
  icon?: boolean;
  id: string;
  order: number | string;
  title: ExpertTitle;
};

export interface ExpertsData {
  experts: {
    nodes: ExpertNode[];
  };
}

export const useExpertsQuery = () => {
  const { experts }: ExpertsData = useStaticQuery(
    graphql`
      query ExpertsQuery {
        experts: allExpertsYaml(sort: { order: ASC, fields: order }) {
          nodes {
            # description
            icon
            id
            order
            title
          }
        }
      }
    `
  );

  return experts;
};
