import React, { FC } from "react";
import clsx from "clsx";

import { Hero, HeroProps, HeroPattern } from "components";
import {
  serviceHero,
  serviceHeroCaption,
  serviceHeroContainer,
} from "./ServiceHero.module.scss";

export interface ServiceHeroProps extends Omit<HeroProps, "is"> {
  hero?: HeroPattern;
}

export const ServiceHero: FC<ServiceHeroProps> = ({
  caption,
  children,
  className,
  container,
  hero = "page",
  ...rest
}) => (
  <Hero
    is={hero}
    color="secondary"
    container={{
      ...container,
      className: clsx(serviceHeroContainer, container?.className),
    }}
    caption={{
      ...caption,
      headingProps: {
        is: "heading",
        ...caption?.headingProps,
      },
      className: clsx(serviceHeroCaption, caption?.className),
    }}
    className={clsx(serviceHero, className)}
    {...(rest as HeroProps)}
  >
    {children}
  </Hero>
);
