import React, { FC } from "react";
import clsx from "clsx";
import { find } from "lodash";

import { Hero, HeroProps, Link, Text } from "components";
import { Brand } from "containers";
import { useMediaQuery } from "graphql";

import { countdownHero } from "./CountdownHero.module.scss";

export const CountdownHero: FC<HeroProps> = ({
  caption,
  children,
  className,
  container,
  is = "page",
  media,
  vh = "full",
  ...rest
}) => {
  const images = useMediaQuery();
  const image = find(images, ["name", "home-hero"]);

  return (
    <Hero
      is={is}
      vh={vh}
      {...(rest as HeroProps)}
      caption={{
        heading: "Coming Soon",
        subheading:
          "A team of designers ready to help level-up your living space. HYH Studio is kicking off in early May 2021.",
        content: (
          <Text
            as="p"
            is="subtitle"
            className="my-2 md:my-3 xl:my-4 font-thin tracking-wider"
          >
            Have a project that can’t wait?{" "}
            <Link
              className="hover:text-accent"
              size="inherit"
              to="mailto:wearehyhstudio@gmail.com?subject=New Project"
            >
              Send us an email
            </Link>{" "}
            and we’ll reach out within one business day.
          </Text>
        ),
        ...caption,
        className: clsx("order-1", caption?.className),
        headingProps: {
          is: "title",
          ...caption?.headingProps,
        },
        subheadingProps: {
          is: "subtitle",
          ...caption?.subheadingProps,
        },
      }}
      container={{
        ...container,
        className: clsx(
          "flex flex-col lg:flex-row items-center text-center lg:text-left",
          container?.className
        ),
      }}
      content={<Brand is="stacked" className="lg:mr-12" />}
      media={
        image && {
          image,
          ...media,
          className: clsx(
            "order-last self-end",
            "max-w-screen-xl mx-auto mt-12",
            media?.className
          ),
        }
      }
      className={clsx(countdownHero, className)}
    >
      {children}
    </Hero>
  );
};
