import React, { FC } from "react";
import clsx from "clsx";

import { Hero, HeroProps, HeroPattern } from "components";

import {
  imagePageHero,
  landingPageHero,
  landingPageHeroCaption,
  landingPageHeroContainer,
  landingPageHeroMedia,
  landingPageHeroSubheading,
  pageHero,
  pageHeroCaption,
  pageHeroMedia,
  pageHeroSubheading,
} from "./PageHero.module.scss";

export type PageHeroPattern = "article" | "content" | "landing";

export interface PageHeroProps extends Omit<HeroProps, "is"> {
  hero?: HeroPattern;
  is?: PageHeroPattern;
}

export const PageHero: FC<PageHeroProps> = ({
  caption,
  children,
  className,
  container,
  hero = "page",
  is,
  media,
  ...rest
}) => (
  <Hero
    is={hero}
    {...(rest as HeroProps)}
    caption={{
      ...caption,
      className: clsx(
        pageHeroCaption,
        is === "landing" && landingPageHeroCaption,
        caption?.className
      ),
      subheadingProps: {
        is: "subtitle",
        ...caption?.subheadingProps,
        className: clsx(
          pageHeroSubheading,
          is === "landing" && landingPageHeroSubheading,
          caption?.subheadingProps?.className
        ),
      },
    }}
    container={{
      ...container,
      is: is === "landing" ? "wide" : is === "content" ? "content" : "default",
      className: clsx(
        is === "landing" && landingPageHeroContainer,
        container?.className
      ),
    }}
    media={{
      ...media,
      className: clsx(
        pageHeroMedia,
        is === "landing" && landingPageHeroMedia,
        media?.className
      ),
    }}
    className={clsx(
      pageHero,
      is === "landing" && landingPageHero,
      media?.image && imagePageHero,
      className
    )}
  >
    {children}
  </Hero>
);
