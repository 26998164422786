import React, { forwardRef } from "react";
import clsx from "clsx";

import { WrapperPrimitive as Wrapper, WrapperProps } from "components";

export type CardPattern = "bordered";

export interface CardProps<E extends HTMLElement = HTMLDivElement>
  extends Omit<WrapperProps<E>, "is"> {
  is?: CardPattern;
}

export const CardPrimitive = <E extends HTMLElement = HTMLDivElement>({
  as = "div",
  children,
  className,
  is,
  ...rest
}: CardProps<E>) => (
  <Wrapper
    as={as}
    {...(rest as WrapperProps)}
    className={clsx(
      "content-center justify-center",
      is === "bordered" && "border-2 xl:border-3 p-4 md:p-6 xl:p-8",
      className
    )}
  >
    {children}
  </Wrapper>
);

export const Card = forwardRef<HTMLDivElement, CardProps<HTMLDivElement>>(
  (props, ref) => <CardPrimitive {...props} innerRef={ref} />
);
