import React, { FC } from "react";

import { CardPrimitive as Card, CardProps, Text } from "components";

import {
  processCardDescription,
  processCardTitle,
} from "./ProcessCard.module.scss";

export interface ProcessCardProps extends CardProps {
  description?: string;
  id: string;
  order: number;
  title: string;
}

export const ProcessCard: FC<ProcessCardProps> = ({
  children,
  className,
  description,
  order,
  title,
  ...rest
}) => (
  <Card {...(rest as CardProps)} className={className}>
    <Text as="h4" is="subheading" className={processCardTitle}>
      {order + 1}. {title}
    </Text>
    {description && (
      <Text as="p" className={processCardDescription}>
        {description}
      </Text>
    )}
    {children}
  </Card>
);
