import React, { FC } from "react";
import clsx from "clsx";

import {
  Card,
  ContainerPrimitive as Container,
  ContainerProps,
  Section,
  SectionProps,
  Text,
} from "components";

import {
  aboutCaption,
  aboutCardTitle,
  aboutGrid,
  aboutIntro,
  aboutIntroText,
  aboutSection,
} from "./About.module.scss";

export interface AboutProps extends SectionProps {
  grid?: ContainerProps;
  intro?: string;
  mission?: string;
  vision?: string;
}

export const About: FC<AboutProps> = ({
  caption,
  children,
  className,
  container,
  grid,
  is = "feature",
  intro = "We’re a team of designers and creatives helping clients level-up their living space. We know the quest from first thought to feasible plans can be daunting. That’s why we specialize in not only drawing the designs but also helping you through the journey of realizing your vision.",
  mission = "We’re here to make homes better and more beautiful through creative design. We choose innovation and originality every day and evolve as technologies, trends, and tastes do.",
  vision = "Our vision is a world where aesthetic design is simple for everyone. An industry free of confusing processes so you can focus on the most important task: executing your idea.",
  ...rest
}) => (
  <Section
    id="about"
    mod="compact"
    className={clsx(aboutSection, className)}
    caption={{
      heading: "About HYH Studio",
      ...caption,
      className: clsx("container", aboutCaption, caption?.className),
    }}
    container={{
      is: "fluid",
      ...container,
    }}
    {...(rest as SectionProps)}
  >
    {intro && (
      <Container as="div" className={aboutIntro}>
        <Text as="h4" className={aboutIntroText}>
          {intro}
        </Text>
      </Container>
    )}
    <Container as="div" {...grid} className={clsx(aboutGrid, grid?.className)}>
      <Card>
        <Text as="h4" is="subheading" className={aboutCardTitle}>
          Our Mission
        </Text>
        {mission && <Text as="p">{mission}</Text>}
      </Card>
      <Card>
        <Text as="h4" is="subheading" className={aboutCardTitle}>
          Our Vision
        </Text>
        {vision && <Text as="p">{vision}</Text>}
      </Card>
    </Container>
    {children}
  </Section>
);
