import React, { FC } from "react";
import clsx from "clsx";
import {
  GiArchBridge,
  GiAutoRepair,
  GiBatteries,
  GiHammerNails,
  GiLaptop,
  GiPipes,
} from "react-icons/gi";

import { CardPrimitive as Card, CardProps, Text } from "components";
import { ExpertTitle } from "graphql";
import { trackEvent } from "utils";

import {
  expertCard,
  expertCardDescription,
  expertCardIcon,
  expertCardTitle,
} from "./ExpertCard.module.scss";

export interface ExpertCardProps extends CardProps {
  description?: string;
  icon?: boolean;
  id: string;
  order?: number | string;
  title: ExpertTitle;
}

export interface ExpertIconProps {
  className?: string;
  title?: ExpertTitle;
}

export const ExpertIcon: FC<ExpertIconProps> = ({ className, title }) => {
  switch (title) {
    case "Contractors":
      return <GiHammerNails className={className} />;
    case "Electrical Engineers":
      return <GiBatteries className={className} />;
    case "Mechanical Engineers":
      return <GiAutoRepair className={className} />;
    case "Plumbing Engineers":
      return <GiPipes className={className} />;
    case "Structural Engineers":
      return <GiArchBridge className={className} />;
    case "Software Engineers":
      return <GiLaptop className={className} />;
    default:
      return null;
  }
};

export const ExpertCard: FC<ExpertCardProps> = ({
  children,
  className,
  description,
  icon,
  order,
  title,
  ...rest
}) => (
  <Card
    {...(rest as CardProps)}
    className={clsx(expertCard, className)}
    onClick={() =>
      trackEvent("ExpertCard", "Click", `User clicked on card: "${title}"`)
    }
  >
    <Text as="h4" is="subtitle" className={expertCardTitle}>
      {icon && <ExpertIcon className={expertCardIcon} title={title} />}
      {title}
    </Text>
    {description && (
      <Text as="p" className={expertCardDescription}>
        {description}
      </Text>
    )}
    {children}
  </Card>
);
