import React, { FC } from "react";
import clsx from "clsx";

import { InputProps } from "components";

import { textInput } from "./TextInput.module.scss";

export interface TextInputProps<E extends HTMLElement = HTMLInputElement>
  extends Omit<InputProps<E>, "max" | "min" | "rows"> {
  rows?: number;
}

export const TextInput: FC<TextInputProps> = <
  E extends HTMLInputElement = HTMLInputElement
>({
  as = "input",
  className,
  id,
  name,
  register,
  rows = 5,
  type = "text",
  ...rest
}: TextInputProps<E>) => {
  if (type === "textarea") {
    return (
      <textarea
        id={id}
        name={name}
        rows={rows}
        {...register}
        className={clsx(textInput, className)}
      />
    );
  }

  return (
    <input
      id={id}
      name={name}
      type={type}
      {...register}
      {...(rest as InputProps)}
      className={clsx(textInput, className)}
    />
  );
};
