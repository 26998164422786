import React, { FC } from "react";
import clsx from "clsx";

import {
  Caption,
  CaptionProps,
  Section,
  SectionProps,
  TestimonyCard,
} from "components";
import { useFeaturedTestimonyQuery } from "graphql";

export interface TestimoniesProps
  extends Omit<SectionProps, "caption" | "media"> {
  caption?: CaptionProps;
  heading?: string;
}

export const Testimonies: FC<TestimoniesProps> = ({
  caption,
  children,
  className,
  container,
  heading = "See what people are saying about their HYH Studio experience",
  is = "feature",
  vh = "3/4",
  ...rest
}) => {
  const testimony = useFeaturedTestimonyQuery();

  return (
    <Section
      is="feature"
      className={className}
      container={container}
      {...(rest as SectionProps)}
    >
      {heading && (
        <Caption
          {...(caption as CaptionProps)}
          heading={heading}
          className={clsx("my-20 md:my-24 xl:my-28", caption?.className)}
        />
      )}
      {children}
      {testimony?.quote && (
        <TestimonyCard className={"my-20 md:my-24 xl:my-28"} {...testimony} />
      )}
    </Section>
  );
};
