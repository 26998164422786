import React, { FC } from "react";
import clsx from "clsx";
import { IoMdQuote } from "react-icons/io";

import { CardPrimitive as Card, CardProps, Text } from "components";

export interface TestimonyCardProps extends CardProps {
  client?: string;
  name?: string;
  quote?: string;
  title?: string;
}

export const TestimonyCard: FC<TestimonyCardProps> = ({
  children,
  className,
  client,
  name,
  quote,
  title,
  ...rest
}) => {
  return (
    <Card
      {...(rest as CardProps)}
      className={clsx(
        "bg-paper text-paper rounded shadow justify-center max-w-screen-md",
        "mx-auto my-4 md:my-8 xl:my-12",
        "p-8 md:p-12 xl:p-16",
        className
      )}
    >
      {children}
      {quote && (
        <>
          <IoMdQuote
            className={clsx(
              "w-6 h-6 md:w-7 md:h-7 xl:w-8 xl:h-8 text-bg-contrast",
              "mb-4 transform rotate-180"
            )}
          />
          <Text
            as="blockquote"
            className={clsx(
              "border-b-2 border-l-2 border-solid border-bg-paper-accent",
              "mb-5 md:mb-4 mr-10 md:mr-14 ml-3 md:ml-3 xl:ml-4",
              "p-4 md:p-6 xl:p-8"
            )}
            is="subtitle"
          >
            {quote}
          </Text>
          <IoMdQuote
            className={clsx(
              "w-6 h-6 md:w-7 md:h-7 xl:w-8 xl:h-8 text-bg-contrast",
              "-mt-8 self-end"
            )}
          />
        </>
      )}
      {name && (
        <Text
          as="cite"
          is="body"
          className={clsx(
            "mt-1 md:mt-4 ml-7 md:ml-9 xl:ml-12",
            "not-italic font-medium"
          )}
        >
          {name}
          {title && `, ${title}`}
        </Text>
      )}
      {client && (
        <Text as="small" is="meta" className="ml-7 md:ml-9 xl:ml-12 font-bold">
          {client}
        </Text>
      )}
    </Card>
  );
};
