import React, { FC } from "react";

import { Box, PostCard, Section, SectionProps, Text } from "components";
import { NewsContext } from "templates";

export interface RecommendedNewsProps extends SectionProps {
  category?: string;
  heading?: string;
  next?: NewsContext;
  prev?: NewsContext;
  subheading?: string;
  tags?: string[];
}

export const RecommendedNews: FC<RecommendedNewsProps> = ({
  children,
  className = "bg-gray-75 dark:bg-gray-500",
  heading = "Read more news",
  next,
  prev,
  subheading,
  ...rest
}) => {
  if (!next && !prev) return null;

  return (
    <Section is="feature" {...(rest as SectionProps)} className={className}>
      {heading && (
        <Text as="h3" className="mb-4 md:mb-5 xl:mb-6 font-light" is="heading">
          {heading}
        </Text>
      )}
      {subheading && (
        <Text as="p" className="mb-8 md:mb-10 xl:mb-12">
          {subheading}
        </Text>
      )}
      {children}
      {(next || prev) && (
        <Box className="sm:grid-cols-6 gap-4 md:gap-6 xl:gap-8 mt-4 md:mt-6 xl:mt-8">
          {prev && (
            <PostCard
              className={`${next ? "sm:col-span-3" : "sm:col-span-6"}`}
              category={prev.category}
              date={prev?.dateCreated}
              excerpt={prev.excerpt}
              title={prev.title}
              to={prev.fields?.slug}
            />
          )}
          {next && (
            <PostCard
              className={`${prev ? "sm:col-span-3" : "sm:col-span-6"}`}
              category={next.category}
              date={next.dateCreated}
              excerpt={next.excerpt}
              title={next.title}
              to={next.fields?.slug}
            />
          )}
        </Box>
      )}
    </Section>
  );
};
