import React, { FC } from "react";
import clsx from "clsx";

import { Hero, HeroProps, HeroPattern } from "components";

import {
  newsHero,
  newsHeroCaption,
  newsHeroHeading,
} from "./NewsHero.module.scss";

export interface NewsHeroProps extends Omit<HeroProps, "is"> {
  hero?: HeroPattern;
}

export const NewsHero: FC<NewsHeroProps> = ({
  caption,
  children,
  className,
  hero = "page",
  media,
  vh = "3/4",
  ...rest
}) => (
  <Hero
    caption={{
      ...caption,
      className: clsx(newsHeroCaption, caption?.className),
      headingProps: {
        ...caption?.headingProps,
        className: clsx(newsHeroHeading, caption?.headingProps?.className),
      },
    }}
    media={{
      mod: "background",
      ...media,
    }}
    is={hero}
    vh={vh}
    {...(rest as HeroProps)}
    className={clsx(newsHero, className)}
  >
    {children}
  </Hero>
);
