import React, { FC } from "react";
import clsx from "clsx";

import {
  BoxPrimitive as Box,
  BoxProps,
  Section,
  SectionProps,
  ExpertCard,
} from "components";
import { ExpertNode, useExpertsQuery } from "graphql";

import { expertsGrid, expertsSection } from "./Experts.module.scss";

export interface ExpertsProps extends SectionProps {
  grid?: BoxProps;
  experts?: ExpertNode[];
}

export const Experts: FC<ExpertsProps> = ({
  caption,
  children,
  className,
  container,
  grid,
  is = "feature",
  experts,
  ...rest
}) => {
  const { nodes } = useExpertsQuery();

  return (
    <Section
      className={clsx(expertsSection, className)}
      caption={{
        subheading: "Need experts in other disciplines? We can help.",
        ...caption,
      }}
      container={container}
      {...(rest as SectionProps)}
    >
      {experts?.length ? (
        <Box {...grid} className={clsx(expertsGrid, grid?.className)}>
          {experts.map((expert) => (
            <ExpertCard key={expert.id} {...expert} />
          ))}
        </Box>
      ) : (
        nodes?.length && (
          <Box {...grid} className={clsx(expertsGrid, grid?.className)}>
            {nodes.map((expert) => (
              <ExpertCard key={expert.id} {...expert} />
            ))}
          </Box>
        )
      )}
      {children}
    </Section>
  );
};
