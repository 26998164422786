import React, { FC, ReactNode } from "react";
import clsx from "clsx";

import {
  BoxPrimitive as Box,
  BoxProps,
  Link,
  LinkProps,
  TextPrimitive as Text,
  TextProps,
} from "components";

export interface CaptionProps extends BoxProps {
  content?: Element | ReactNode;
  footer?: Element | ReactNode;
  header?: Element | ReactNode;
  heading?: string;
  headingProps?: TextProps;
  meta?: string;
  metaProps?: LinkProps | TextProps;
  subheading?: string;
  subheadingProps?: TextProps;
}

export const Caption: FC<CaptionProps> = ({
  as = "figcaption",
  children,
  className,
  content,
  footer,
  header,
  heading,
  headingProps,
  meta,
  metaProps,
  subheading,
  subheadingProps,
  ...rest
}) => (
  <Box
    as={as}
    {...(rest as BoxProps)}
    className={clsx(
      "max-w-screen-md z-10",
      "ml-0 my-6 md:my-8 xl:my-10",
      className
    )}
  >
    {header}
    {meta &&
      (metaProps?.to ? (
        <Link
          text="subheading"
          {...(metaProps as LinkProps)}
          to={metaProps.to}
          className={clsx(
            "mb-4 md:mb-5 xl:mb-6 font-normal",
            metaProps?.className
          )}
        >
          {meta}
        </Link>
      ) : (
        <Text
          as="small"
          is="subheading"
          {...(metaProps as TextProps)}
          className={clsx(
            "mb-4 md:mb-5 xl:mb-6 font-normal",
            metaProps?.className
          )}
        >
          {meta}
        </Text>
      ))}
    {heading &&
      (headingProps?.to ? (
        <Link to={headingProps.to}>
          <Text
            as="h3"
            is="heading"
            {...(headingProps as TextProps)}
            className={clsx(
              "leading-tight tracking-wide",
              headingProps?.className
            )}
          >
            {heading}
          </Text>
        </Link>
      ) : (
        <Text
          as="h3"
          is="heading"
          {...(headingProps as TextProps)}
          className={clsx(
            "leading-tight tracking-wide",
            headingProps?.className
          )}
        >
          {heading}
        </Text>
      ))}
    {subheading && (
      <Text
        as="h4"
        is="subheading"
        {...(subheadingProps as TextProps)}
        className={clsx(
          "mt-2 font-normal tracking-wider",
          subheadingProps?.className
        )}
      >
        {subheading}
      </Text>
    )}
    {content}
    {children}
    {footer}
  </Box>
);
