import React, { FC } from "react";
import clsx from "clsx";

import { Link, LinkProps, Media, Text } from "components";
import { GatsbyImageProps } from "types";

import {
  serviceCard,
  serviceCardImage,
  serviceCardTitle,
} from "./ServiceCard.module.scss";

export interface ServiceCardProps extends LinkProps {
  description?: string;
  image?: GatsbyImageProps;
  title: string;
}

export const ServiceCard: FC<ServiceCardProps> = ({
  children,
  className,
  image,
  title,
  to,
  ...rest
}) => (
  <Link
    {...(rest as LinkProps)}
    to={to}
    className={clsx(serviceCard, className)}
  >
    {image && <Media className={serviceCardImage} image={image} />}
    <Text as="h4" is="meta" className={serviceCardTitle}>
      {title}
    </Text>
    {children}
  </Link>
);
