import { useStaticQuery, graphql } from "gatsby";

import { GatsbyDate, GatsbyImageProps } from "types";

export type NewsFields = {
  slug: string;
};

export type NewsFrontmatter = {
  author?: string;
  category?: string;
  dateCreated: GatsbyDate;
  dateUpdated?: GatsbyDate;
  description?: string;
  featured?: boolean;
  image: GatsbyImageProps;
  tags?: string[];
  title: string;
};

export type NewsNode = {
  excerpt: string;
  fields: NewsFields;
  frontmatter: NewsFrontmatter;
  id: string;
};

export interface NewsData {
  news: {
    nodes: NewsNode[];
  };
}

export const useNewsQuery = () => {
  const { news }: NewsData = useStaticQuery(
    graphql`
      query newsQuery {
        news: allMdx(
          filter: {
            slug: { regex: "/news/" }
            frontmatter: { published: { eq: true }, template: { eq: "news" } }
          }
          sort: { order: DESC, fields: frontmatter___dateCreated }
        ) {
          nodes {
            excerpt
            fields {
              slug
            }
            frontmatter {
              author
              category
              dateCreated(formatString: "MMMM DD, YYYY")
              dateUpdated(formatString: "MMMM DD, YYYY")
              description
              featured
              image {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP, AVIF]
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
                name
              }
              tags
              title
            }
            id
          }
        }
      }
    `
  );

  return news;
};
