import { useStaticQuery, graphql } from "gatsby";

import { GatsbyImageProps } from "types";

export interface BrandQuery {
  horizontalLogo?: GatsbyImageProps;
  horizontalLogoWhite?: GatsbyImageProps;
  logo: GatsbyImageProps;
  logoWhite: GatsbyImageProps;
  stackedLogo?: GatsbyImageProps;
  stackedLogoWhite?: GatsbyImageProps;
  site: {
    siteMetadata: {
      acronym?: string;
      name?: string;
      title?: string;
    };
  };
}

export const brandLogoMediaFragment = graphql`
  fragment BrandLogoMediaFragment on File {
    childImageSharp {
      gatsbyImageData(
        formats: [AUTO, WEBP, AVIF]
        layout: CONSTRAINED
        placeholder: BLURRED
        quality: 90
        width: 1280
      )
    }
    id
    name
  }
`;

export const brandSiteMetadataFragment = graphql`
  fragment BrandSiteMetadataFragment on Site {
    siteMetadata {
      acronym
      name
      title
    }
  }
`;

export const useBrandQuery = () => {
  const {
    horizontalLogo,
    horizontalLogoWhite,
    logo,
    logoWhite,
    stackedLogo,
    stackedLogoWhite,
    site: {
      siteMetadata: { acronym, name, title },
    },
  }: BrandQuery = useStaticQuery(
    graphql`
      query BrandQuery {
        logo: file(relativePath: { eq: "logo.png" }) {
          ...BrandLogoMediaFragment
        }
        logoWhite: file(relativePath: { eq: "logo-white.png" }) {
          ...BrandLogoMediaFragment
        }
        horizontalLogo: file(relativePath: { eq: "logo-horizontal.png" }) {
          ...BrandLogoMediaFragment
        }
        horizontalLogoWhite: file(
          relativePath: { eq: "logo-horizontal-white.png" }
        ) {
          ...BrandLogoMediaFragment
        }
        stackedLogo: file(relativePath: { eq: "logo-stacked.png" }) {
          ...BrandLogoMediaFragment
        }
        stackedLogoWhite: file(relativePath: { eq: "logo-stacked-white.png" }) {
          ...BrandLogoMediaFragment
        }
        site {
          ...SiteMetadataFragment
        }
      }
    `
  );

  return {
    horizontalLogo,
    horizontalLogoWhite,
    logo,
    logoWhite,
    stackedLogo,
    stackedLogoWhite,
    acronym,
    name,
    title,
  };
};
