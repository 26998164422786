import { useStaticQuery, graphql } from "gatsby";

import { ServicesData } from "graphql";

export const useFeaturedServicesQuery = () => {
  const { services }: ServicesData = useStaticQuery(
    graphql`
      query featuredServicesQuery {
        services: allMdx(
          filter: {
            slug: { regex: "/services/" }
            frontmatter: {
              published: { eq: true }
              featured: { eq: true }
              template: { eq: "service" }
            }
          }
          sort: { order: ASC, fields: frontmatter___dateCreated }
        ) {
          nodes {
            excerpt
            fields {
              slug
            }
            frontmatter {
              dateCreated(formatString: "MMMM DD, YYYY")
              dateUpdated(formatString: "MMMM DD, YYYY")
              featuredDescription
              featuredImage {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP, AVIF]
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
                name
              }
              title
            }
            id
          }
        }
      }
    `
  );

  return services;
};
