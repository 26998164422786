import React, { FC } from "react";
import clsx from "clsx";

import { ListPrimitive as List, ListItem, ListProps } from "components";
import { HeaderMenuLink, HeaderMenuProps } from "containers";

import {
  newsHeaderMenuItem,
  newsHeaderMenuLink,
  newsHeaderMenuList,
} from "./NewsHeaderMenu.module.scss";

export const NewsHeaderMenu: FC<HeaderMenuProps> = ({
  children,
  className,
  link,
  location,
  showNavLinks = true,
  ...rest
}) => (
  <List
    as="ul"
    is="inline"
    {...(rest as ListProps)}
    className={clsx(newsHeaderMenuList, className)}
  >
    {showNavLinks && (
      <>
        <ListItem className={newsHeaderMenuItem}>
          <HeaderMenuLink
            className={newsHeaderMenuLink}
            color="inherit"
            {...link}
            to="/#services"
          >
            services
          </HeaderMenuLink>
        </ListItem>
        <ListItem className={newsHeaderMenuItem}>
          <HeaderMenuLink
            className={newsHeaderMenuLink}
            color="inherit"
            {...link}
            to="/#about"
          >
            about
          </HeaderMenuLink>
        </ListItem>
        <ListItem className={newsHeaderMenuItem}>
          <HeaderMenuLink
            className={newsHeaderMenuLink}
            color="inherit"
            {...link}
            to="/news#contact"
          >
            contact
          </HeaderMenuLink>
        </ListItem>
        <ListItem className={newsHeaderMenuItem}>
          <HeaderMenuLink
            className={newsHeaderMenuLink}
            color="inherit"
            {...link}
            to="/news"
          >
            news
          </HeaderMenuLink>
        </ListItem>
      </>
    )}
    {children}
  </List>
);
