import React, { FC } from "react";
import Scrollspy from "react-scrollspy";
import clsx from "clsx";

import {
  Link,
  LinkProps,
  ListPrimitive as List,
  ListItem,
  ListProps,
} from "components";
import { GatsbyLocation } from "types";

import {
  activeHeaderMenuItem,
  activeHeaderMenuLink,
  headerMenuItem,
  headerMenuLink,
  headerMenuList,
} from "./HeaderMenu.module.scss";

export interface HeaderMenuProps extends ListProps {
  link?: LinkProps;
  location?: GatsbyLocation;
  showNavLinks?: boolean;
}

export const HeaderMenuLink: FC<LinkProps> = ({
  activeClassName = "underline",
  children,
  className,
  ...rest
}) => (
  <Link
    activeClassName={activeClassName}
    text="caption"
    {...(rest as LinkProps)}
    className={clsx(headerMenuLink, className)}
  >
    {children}
  </Link>
);

export const HeaderMenu: FC<HeaderMenuProps> = ({
  children,
  className,
  link,
  location,
  showNavLinks = true,
  ...rest
}) => (
  <List
    as="nav"
    is="inline"
    {...(rest as ListProps)}
    className={clsx(headerMenuList, className)}
  >
    {showNavLinks && (
      <Scrollspy
        items={["services", "about", "contact"]}
        currentClassName={activeHeaderMenuItem}
      >
        <ListItem className={headerMenuItem}>
          <HeaderMenuLink color="inherit" {...link} to="/#services">
            services
          </HeaderMenuLink>
        </ListItem>
        <ListItem className={headerMenuItem}>
          <HeaderMenuLink color="inherit" {...link} to="/#about">
            about
          </HeaderMenuLink>
        </ListItem>
        <ListItem className={headerMenuItem}>
          <HeaderMenuLink color="inherit" {...link} to="#contact">
            contact
          </HeaderMenuLink>
        </ListItem>
        <ListItem className={headerMenuItem}>
          <HeaderMenuLink
            color="inherit"
            {...link}
            activeClassName={clsx(activeHeaderMenuLink, link?.activeClassName)}
            to="/news"
          >
            news
          </HeaderMenuLink>
        </ListItem>
      </Scrollspy>
    )}
    {children}
  </List>
);
