import React, { FC } from "react";
import clsx from "clsx";

import {
  Box,
  ButtonLink,
  CardPrimitive as Card,
  CardProps,
  Media,
  Text,
} from "components";
import { GatsbyDate, GatsbyImageProps } from "types";

import {
  newsCard,
  newsCardMediaLink,
  newsCardMedia,
  newsCardContent,
  newsCardTitle,
  newsCardExcerpt,
  newsCardMeta,
} from "./NewsCard.module.scss";

export interface NewsCardProps extends CardProps {
  category?: string;
  date?: GatsbyDate;
  excerpt?: string;
  image?: GatsbyImageProps;
  title?: string;
  to: string;
}

export const NewsCard: FC<NewsCardProps> = ({
  category,
  children,
  className,
  date,
  excerpt,
  image,
  title,
  to,
  ...rest
}) => (
  <Card {...(rest as CardProps)} className={clsx(newsCard, className)}>
    {image && (
      <ButtonLink to={to} is="wrapper" className={newsCardMediaLink}>
        <Media className={newsCardMedia} image={image} />
      </ButtonLink>
    )}
    <Box className={newsCardContent}>
      {title && (
        <ButtonLink to={to} is="wrapper">
          <Text as="h3" is="title" className={newsCardTitle}>
            {title}
          </Text>
        </ButtonLink>
      )}
      {(category || date) && (
        <Text as="small" is="caption" className={newsCardMeta}>
          Posted{category && ` in ${category}`}
          {date ? ` on ${date}.` : "."}
        </Text>
      )}
      {excerpt && (
        <Text as="h4" className={newsCardExcerpt}>
          {excerpt}
        </Text>
      )}
      <ButtonLink to={to} button="contained" color="secondary">
        Read More
      </ButtonLink>
    </Box>
  </Card>
);
