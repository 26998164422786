import React, { FC, forwardRef } from "react";
import clsx from "clsx";

import { BoxColor, BoxProps } from "./types";

export const boxColor = (color: BoxColor): string => {
  switch (color) {
    case "contrast":
      return "bg-contrast text-contrast";
    case "paper":
      return "bg-paper text-paper";
    case "primary":
      return "bg-salt text-pepper";
    case "secondary":
      return "bg-muted-gold text-black";
    case "transparent":
      return "bg-transparent border-transparent";
    case "inherit":
      return "bg-inherit text-inherit";
    default:
      return "bg-default text-default";
  }
};

export const BoxPrimitive: FC<BoxProps> = <
  E extends HTMLElement = HTMLDivElement
>({
  as = "div",
  children,
  className,
  color,
  innerRef,
  position,
  ...rest
}: BoxProps<E>) => {
  const Component = as;

  return (
    <Component
      {...rest}
      className={clsx(color && boxColor(color), className)}
      position={position}
      ref={innerRef}
    >
      {children}
    </Component>
  );
};

export const Box = forwardRef<HTMLDivElement, BoxProps<HTMLDivElement>>(
  (props, ref) => <BoxPrimitive {...props} innerRef={ref} />
);
