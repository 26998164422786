import React, { FC } from "react";
import clsx from "clsx";

import {
  BoxPrimitive as Box,
  BoxProps,
  NewsCard,
  Section,
  SectionProps,
} from "components";
import { NewsNode, useNewsQuery } from "graphql";

import { newsGrid, newsSection } from "./DefaultNews.module.scss";

export interface NewsProps extends SectionProps {
  grid?: BoxProps;
  news?: NewsNode[];
}

export const News: FC<NewsProps> = ({
  caption,
  children,
  className,
  container,
  grid,
  is = "feature",
  news,
  ...rest
}) => {
  const { nodes } = useNewsQuery();

  return (
    <Section
      id="news"
      mod="compact"
      className={clsx(newsSection, className)}
      caption={caption}
      container={container}
      {...(rest as SectionProps)}
    >
      {news?.length ? (
        <Box {...grid} className={clsx(newsGrid, grid?.className)}>
          {news.map(
            ({
              fields: { slug },
              frontmatter: { description, image, title },
              id,
            }) => (
              <NewsCard
                key={id}
                excerpt={description}
                image={image}
                title={title}
                to={slug}
              />
            )
          )}
        </Box>
      ) : (
        nodes?.length && (
          <Box {...grid} className={clsx(newsGrid, grid?.className)}>
            {nodes.map(
              ({
                fields: { slug },
                frontmatter: {
                  category,
                  dateCreated,
                  description,
                  image,
                  title,
                },
                id,
              }) => (
                <NewsCard
                  category={category}
                  date={dateCreated}
                  key={id}
                  excerpt={description}
                  image={image}
                  title={title}
                  to={slug}
                />
              )
            )}
          </Box>
        )
      )}
      {children}
    </Section>
  );
};
