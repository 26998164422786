import React, { FC } from "react";
import clsx from "clsx";

import {
  Container,
  MDX,
  MDXProps,
  Media,
  MediaProps,
  Section,
  SectionProps,
} from "components";
import { GatsbyImageProps } from "types";

import {
  serviceSectionArticle,
  serviceSectionArticleContainer,
  serviceSectionMedia,
  serviceSectionContainer,
} from "./ServiceSection.module.scss";

export interface ServiceSectionProps extends SectionProps {
  body?: string;
  image?: GatsbyImageProps;
  mdx?: MDXProps;
  media?: MediaProps;
}

export const ServiceSection: FC<ServiceSectionProps> = ({
  body,
  children,
  className,
  container,
  image,
  mdx,
  media,
  ...rest
}) => (
  <Section
    mod="compact"
    className={className}
    container={{
      is: "fluid",
      ...container,
      className: clsx(serviceSectionContainer, container?.className),
    }}
    {...(rest as SectionProps)}
  >
    {image && (
      <Media {...media} className={serviceSectionMedia} image={image} />
    )}
    {children}
    {body && (
      <Container as="div" className={serviceSectionArticleContainer}>
        <MDX
          {...mdx}
          className={clsx(serviceSectionArticle, mdx?.className)}
          body={body}
        />
      </Container>
    )}
  </Section>
);
