import React, { FC } from "react";
import {
  GiBrain,
  GiConqueror,
  GiLockedFortress,
  GiLoveMystery,
} from "react-icons/gi";

import { CardPrimitive as Card, CardProps, Text } from "components";
import { ValueTitle } from "graphql";

import {
  valueCardDescription,
  valueCardIcon,
  valueCardTitle,
} from "./ValueCard.module.scss";

export interface ValueCardProps extends CardProps {
  description?: string;
  icon?: boolean;
  id: string;
  order?: number | string;
  title: ValueTitle;
}

export interface ValueIconProps {
  className?: string;
  value?: ValueTitle;
}

export const ValueIcon: FC<ValueIconProps> = ({ className, value }) => {
  switch (value) {
    case "Ingenuity":
      return <GiBrain className={className} />;
    case "Originality":
      return <GiConqueror className={className} />;
    case "Integrity":
      return <GiLockedFortress className={className} />;
    case "Diversity":
      return <GiLoveMystery className={className} />;
    default:
      return null;
  }
};

export const ValueCard: FC<ValueCardProps> = ({
  children,
  className,
  description,
  icon,
  order,
  title,
  ...rest
}) => (
  <Card {...(rest as CardProps)} className={className}>
    <Text as="h4" is="subheading" className={valueCardTitle}>
      {icon && <ValueIcon className={valueCardIcon} value={title} />}
      {title}
    </Text>
    {description && (
      <Text as="p" className={valueCardDescription}>
        {description}
      </Text>
    )}
    {children}
  </Card>
);
