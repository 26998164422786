import React, { FC } from "react";
import clsx from "clsx";

import { Section, SectionProps } from "components";

import { HeroPattern } from "./types";

export interface HeroProps extends Omit<SectionProps, "is"> {
  is?: HeroPattern;
}

export const Hero: FC<HeroProps> = ({
  as = "header",
  caption,
  children,
  color,
  className,
  container,
  is = "page",
  vh,
  ...rest
}) => (
  <Section
    as={as}
    color={color}
    is="hero"
    vh={vh}
    {...(rest as SectionProps)}
    caption={{
      ...caption,
      headingProps: {
        as: "h1",
        is: "hero",
        ...caption?.headingProps,
      },
      subheadingProps: {
        as: "h2",
        ...caption?.subheadingProps,
      },
    }}
    container={{
      ...container,
      className: clsx("items-start", container?.className),
    }}
    className={clsx(
      is === "card" && "max-w-screen-md py-14 md:py-16 xl:py-18",
      is === "default" && "py-16 md:py-18 xl:py-20",
      is === "page" && "py-18 md:py-20 xl:py-24",
      is === "panel" && "py-4 md:py-6 xl:py-8",
      className
    )}
  >
    {children}
  </Section>
);
