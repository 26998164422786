import { useStaticQuery, graphql } from "gatsby";

export type ProcessNode = {
  description?: string;
  id: string;
  order: number;
  title: string;
};

export interface ProcessesData {
  processes: {
    nodes: ProcessNode[];
  };
}

export const useProcessesQuery = () => {
  const { processes }: ProcessesData = useStaticQuery(
    graphql`
      query ProcessesQuery {
        processes: allProcessesYaml(sort: { order: ASC, fields: order }) {
          nodes {
            description
            id
            order
            title
          }
        }
      }
    `
  );

  return processes;
};
