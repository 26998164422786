import React, { FC } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import clsx from "clsx";
import { kebabCase } from "lodash";

import { BoxProps } from "components";
import { BrandColor, BrandPattern } from "containers";
import { useBrandQuery } from "graphql";
import { GatsbyImageProps } from "types";

export interface BrandLogoProps extends Omit<BoxProps, "color"> {
  color?: BrandColor;
  image?: GatsbyImageProps;
  is?: BrandPattern;
}

export const BrandLogo: FC<BrandLogoProps> = ({
  className,
  color,
  image,
  is = "default",
}) => {
  const {
    horizontalLogo,
    horizontalLogoWhite,
    logo,
    logoWhite,
    name,
    stackedLogo,
    stackedLogoWhite,
  } = useBrandQuery();

  switch (is) {
    case "horizontal":
      if (color === "white") {
        if (!horizontalLogoWhite?.childImageSharp) return null;
        const altHorizontalWhite = kebabCase(
          `${name}-${horizontalLogoWhite.name}`
        );

        return (
          <GatsbyImage
            {...image}
            alt={altHorizontalWhite}
            className={clsx("w-40 md:w-44 xl:w-48", className)}
            image={horizontalLogoWhite.childImageSharp.gatsbyImageData}
          />
        );
      }

      if (!horizontalLogo?.childImageSharp) return null;
      const altHorizontal = kebabCase(`${name}-${horizontalLogo.name}`);

      return (
        <GatsbyImage
          {...image}
          alt={altHorizontal}
          className={clsx("w-40 md:w-44 xl:w-48", className)}
          image={horizontalLogo.childImageSharp.gatsbyImageData}
        />
      );
    case "stacked":
      if (color === "white") {
        if (!stackedLogoWhite?.childImageSharp) return null;
        const altStackedWhite = kebabCase(`${name}-${stackedLogoWhite.name}`);

        return (
          <GatsbyImage
            {...image}
            alt={altStackedWhite}
            className={clsx("w-16 md:w-20 xl:w-24", className)}
            image={stackedLogoWhite.childImageSharp.gatsbyImageData}
          />
        );
      }

      if (!stackedLogo?.childImageSharp) return null;
      const altStacked = kebabCase(`${name}-${stackedLogo.name}`);

      return (
        <GatsbyImage
          {...image}
          alt={altStacked}
          className={clsx("w-16 md:w-20 xl:w-24", className)}
          image={stackedLogo.childImageSharp.gatsbyImageData}
        />
      );
    case "default":
    default:
      if (color === "white") {
        if (!logoWhite?.childImageSharp) return null;
        const altWhite = kebabCase(`${name}-${logoWhite.name}`);

        return (
          <GatsbyImage
            {...image}
            alt={altWhite}
            className={clsx("w-14 md:w-16 xl:w-18", className)}
            image={logoWhite.childImageSharp.gatsbyImageData}
          />
        );
      }

      if (!logo?.childImageSharp) return null;
      const alt = kebabCase(`${name}-${logo.name}`);

      return (
        <GatsbyImage
          {...image}
          alt={alt}
          className={clsx("w-14 md:w-16 xl:w-18", className)}
          image={logo.childImageSharp.gatsbyImageData}
        />
      );
  }
};
