import { useStaticQuery, graphql } from "gatsby";

export type ValueTitle =
  | "Ingenuity"
  | "Originality"
  | "Integrity"
  | "Diversity"
  | string;

export interface ValueNode {
  description?: string;
  icon?: boolean;
  id: string;
  order: number | string;
  title: ValueTitle;
}

export interface ValuesData {
  values: {
    nodes: ValueNode[];
  };
}

export const useValuesQuery = () => {
  const { values }: ValuesData = useStaticQuery(
    graphql`
      query ValuesQuery {
        values: allValuesYaml(sort: { order: ASC, fields: order }) {
          nodes {
            description
            icon
            id
            order
            title
          }
        }
      }
    `
  );

  return values;
};
