import { useStaticQuery, graphql } from "gatsby";

import { Form } from "containers";

export type FormData = {
  contactForm: Form;
};

export const useContactFormQuery = () => {
  const { contactForm }: FormData = useStaticQuery(
    graphql`
      query ContactFormQuery {
        contactForm: formsYaml(name: { eq: "contact" }) {
          # action
          button
          formFields {
            label
            name
            order
            placeholder
            required
            type
          }
          method
          name
        }
      }
    `
  );

  return contactForm;
};
