import React, { FC, ReactNode } from "react";
import clsx from "clsx";

import {
  BoxPrimitive as Box,
  BoxProps,
  ButtonLink,
  ButtonLinkProps,
  Media,
  MediaProps,
  Text,
  WrapperPrimitive as Wrapper,
  WrapperProps,
} from "components";

export interface FeaturedCTAProps extends Omit<BoxProps, "media"> {
  attribute?: Element | ReactNode;
  button?: ButtonLinkProps;
  content?: Element | ReactNode;
  contentProps?: WrapperProps;
  cta?: BoxProps;
  footnote?: Element | ReactNode;
  heading?: string;
  media?: MediaProps;
  mediaProps?: WrapperProps;
  to?: string;
}

export const FeaturedCTA: FC<FeaturedCTAProps> = ({
  attribute,
  button,
  children,
  className,
  content,
  contentProps,
  footnote,
  heading,
  media,
  mediaProps,
  to,
  ...rest
}) => (
  <Box
    {...(rest as BoxProps)}
    className={clsx(
      "grid md:grid-cols-2 md:gap-6 lg:gap-4 xl:gap-2",
      "content-center items-center md:items-start",
      className
    )}
  >
    {media && (
      <Wrapper
        {...mediaProps}
        className={clsx(
          "items-center md:order-1 md:-mr-4 lg:-mr-6 xl:-mr-12",
          mediaProps?.className
        )}
      >
        {to ? (
          <ButtonLink is="wrapper" className={"w-full"} to={to}>
            <Media {...(media as MediaProps)} />
          </ButtonLink>
        ) : (
          <Media {...(media as MediaProps)} />
        )}
        {attribute}
      </Wrapper>
    )}
    {children}
    <Wrapper
      {...contentProps}
      className={clsx("items-center md:items-start", contentProps?.className)}
    >
      {heading && (
        <Text
          as="h2"
          className="font-light leading-snug tracking-wider"
          is="subheading"
        >
          {heading}
        </Text>
      )}
      {content}
      {button && to && (
        <ButtonLink {...(button as ButtonLinkProps)} to={to}>
          {button.label}
        </ButtonLink>
      )}
      {footnote}
    </Wrapper>
  </Box>
);
