import React, { FC } from "react";
import clsx from "clsx";

import {
  BoxPrimitive as Box,
  BoxProps,
  Section,
  SectionProps,
  ServiceCard,
} from "components";
import { ServiceNode, useServicesQuery } from "graphql";

import {
  servicesCaption,
  servicesGrid,
  servicesSection,
} from "./Services.module.scss";

export interface ServicesProps extends SectionProps {
  grid?: BoxProps;
  services?: ServiceNode[];
}

export const Services: FC<ServicesProps> = ({
  caption,
  children,
  className,
  container,
  grid,
  is = "feature",
  services,
  ...rest
}) => {
  const { nodes } = useServicesQuery();

  return (
    <Section
      id="services"
      mod="compact"
      className={clsx(servicesSection, className)}
      caption={{
        heading: "Our Services",
        ...caption,
        className: clsx("container", servicesCaption, caption?.className),
      }}
      container={{
        is: "fluid",
        ...container,
      }}
      {...(rest as SectionProps)}
    >
      {services?.length ? (
        <Box {...grid} className={clsx(servicesGrid, grid?.className)}>
          {services.map(
            ({ fields: { slug }, frontmatter: { image, title }, id }) => (
              <ServiceCard
                key={id}
                // description={description}
                image={image}
                title={title}
                to={slug}
              />
            )
          )}
        </Box>
      ) : (
        nodes?.length && (
          <Box {...grid} className={clsx(servicesGrid, grid?.className)}>
            {nodes.map(
              ({ fields: { slug }, frontmatter: { image, title }, id }) => (
                <ServiceCard
                  key={id}
                  // description={description}
                  image={image}
                  title={title}
                  to={slug}
                />
              )
            )}
          </Box>
        )
      )}
      {children}
    </Section>
  );
};
