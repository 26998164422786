import React, { FC } from "react";
import Select, { NamedProps } from "react-select";
import clsx from "clsx";

import "./SelectInput.scss";

export interface SelectInputProps extends NamedProps {}

export const SelectInput: FC<SelectInputProps> = ({
  className,
  name,
  options,
  placeholder,
  onChange,
  ...rest
}) => (
  <Select
    name={name}
    className={clsx("select-input", className)}
    classNamePrefix="select-input"
    options={options}
    placeholder={placeholder}
    onChange={onChange}
    {...rest}
  />
);
