import React, { FC } from "react";
import clsx from "clsx";
import { FieldError, UseFormRegisterReturn } from "react-hook-form";

import {
  InputType,
  SelectOption,
  Text,
  TextInput,
  TextInputProps,
  WrapperPrimitive as Wrapper,
  WrapperProps,
} from "components";

export interface FormFieldProps extends WrapperProps {
  error?: FieldError;
  errorMessage?: string;
  id?: string;
  input?: TextInputProps;
  label?: string;
  name: string;
  options?: readonly SelectOption[];
  register?: UseFormRegisterReturn;
  type?: InputType;
}

export const FormField: FC<FormFieldProps> = ({
  children,
  className,
  error,
  errorMessage = "This field is required",
  id,
  input,
  label,
  name,
  options,
  register,
  type = "text",
  ...rest
}) => (
  <Wrapper
    as="div"
    {...(rest as WrapperProps)}
    className={clsx("content-start items-stretch", className)}
  >
    {label && (
      <Text as="label" is="meta" className="mb-1" htmlFor={id || name}>
        {label}
      </Text>
    )}
    {children ? (
      children
    ) : (
      <TextInput
        id={id || name}
        name={name}
        register={register}
        type={type}
        {...input}
      />
    )}
    {error && (
      <Text is="caption" className="mt-1 text-accent">
        {error.message || errorMessage}
      </Text>
    )}
  </Wrapper>
);
