import { useStaticQuery, graphql } from "gatsby";

import { GatsbyDate, GatsbyImageProps } from "types";

export type ServiceFields = {
  slug: string;
};

export type ServiceFrontmatter = {
  dateCreated: GatsbyDate;
  dateUpdated?: GatsbyDate;
  description?: string;
  featured?: boolean;
  featuredDescription?: string;
  featuredImage?: GatsbyImageProps;
  image: GatsbyImageProps;
  tags?: string[];
  title: string;
};

export type ServiceNode = {
  excerpt: string;
  fields: ServiceFields;
  frontmatter: ServiceFrontmatter;
  id: string;
};

export interface ServicesData {
  services: {
    nodes: ServiceNode[];
  };
}

export const useServicesQuery = () => {
  const { services }: ServicesData = useStaticQuery(
    graphql`
      query servicesQuery {
        services: allMdx(
          filter: {
            slug: { regex: "/services/" }
            frontmatter: {
              published: { eq: true }
              template: { eq: "service" }
            }
          }
          sort: { order: ASC, fields: frontmatter___dateCreated }
        ) {
          nodes {
            excerpt
            fields {
              slug
            }
            frontmatter {
              dateCreated(formatString: "MMMM DD, YYYY")
              dateUpdated(formatString: "MMMM DD, YYYY")
              description
              image {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP, AVIF]
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
                name
              }
              tags
              title
            }
            id
          }
        }
      }
    `
  );

  return services;
};
