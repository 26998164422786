import React, { FC, ReactNode } from "react";
import clsx from "clsx";

import {
  Caption,
  CaptionProps,
  ContainerPrimitive as Container,
  ContainerProps,
  Media,
  MediaProps,
  WrapperPrimitive as Wrapper,
  WrapperProps,
} from "components";

import { SectionMod, SectionPattern, SectionVerticalHeight } from "./types";
import {
  defaultSection,
  contentSection,
  ctaSection,
  featureSection,
  heelSection,
  heroSection,
  layoutSection,
  navbarSection,
  sectionCol,
  sectionCompact,
  sectionFluid,
  sectionFull,
  sectionInherit,
  sectionRow,
  sectionTransparent,
} from "./Section.module.scss";

export interface SectionProps extends Omit<WrapperProps, "is" | "media"> {
  caption?: CaptionProps;
  container?: ContainerProps;
  content?: Element | ReactNode;
  footer?: Element | ReactNode;
  header?: Element | ReactNode;
  isFluid?: boolean;
  is?: SectionPattern;
  media?: MediaProps;
  mod?: SectionMod;
  vh?: SectionVerticalHeight;
}

export const Section: FC<SectionProps> = ({
  caption,
  children,
  className,
  container,
  content,
  footer,
  header,
  is = "content",
  isFluid = false,
  media,
  mod,
  vh,
  ...rest
}) => (
  <Wrapper
    {...(rest as WrapperProps)}
    className={clsx(
      defaultSection,
      is === "content" && contentSection,
      is === "cta" && ctaSection,
      is === "feature" && featureSection,
      is === "heel" && heelSection,
      is === "hero" && heroSection,
      is === "layout" && layoutSection,
      is === "navbar" && navbarSection,
      mod?.includes("col") && sectionCol,
      mod?.includes("compact") && sectionCompact,
      mod?.includes("fluid") && sectionFluid,
      mod?.includes("full") && sectionFull,
      mod?.includes("inherit") && sectionInherit,
      mod?.includes("row") && sectionRow,
      mod?.includes("transparent") && sectionTransparent,
      vh === "auto" && "min-h-auto",
      vh === "full" && "min-h-screen",
      vh === "11/12" && "min-h-11/12",
      vh === "5/6" && "min-h-screen-5/6",
      vh === "4/5" && "min-h-screen-4/5",
      vh === "3/4" && "min-h-screen-3/4",
      vh === "2/3" && "min-h-screen-2/3",
      vh === "3/5" && "min-h-screen-3/5",
      vh === "1/2" && "min-h-screen-1/2",
      vh === "2/5" && "min-h-screen-2/5",
      vh === "1/3" && "min-h-screen-1/3",
      vh === "1/4" && "min-h-screen-1/4",
      vh === "1/5" && "min-h-screen-1/5",
      vh === "1/6" && "min-h-screen-1/6",
      vh === "1/12" && "min-h-screen-1/12",
      vh === "zero" && "min-h-0",
      className
    )}
  >
    {header}
    {media?.image && <Media {...media} className={clsx(media.className)} />}
    <Container
      {...container}
      is={isFluid ? "fluid" : container?.is}
      className={clsx("z-10", container?.className)}
    >
      {caption && <Caption {...caption} />}
      {content}
      {children}
    </Container>
    {footer}
  </Wrapper>
);
