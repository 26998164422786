import React, { FC } from "react";
import clsx from "clsx";
import { SiMailchimp } from "react-icons/si";
import { RiInstagramFill, RiLinkedinBoxFill } from "react-icons/ri";

import {
  ButtonLink,
  ButtonLinkProps,
  Link,
  LinkProps,
  ListPrimitive as List,
  ListProps,
  WrapperPrimitive as Wrapper,
  WrapperProps,
} from "components";
import { useSiteMetadataQuery } from "graphql";
import { GatsbyLocation } from "types";

export interface FooterMenuProps extends WrapperProps {
  link?: ButtonLinkProps | LinkProps;
  location?: GatsbyLocation;
  nav?: ListProps;
  showNavLinks?: boolean;
  showSocialMedia?: boolean;
}

export const FooterMenuNav: FC<ListProps> = ({
  children,
  className,
  ...rest
}) => (
  <List
    as="nav"
    {...(rest as ListProps)}
    className={clsx(
      "flex flex-row flex-wrap",
      "items-center justify-center md:justify-start",
      "mt-3 space-x-4 md:space-x-5 xl:space-x-6",
      className
    )}
  >
    {children}
  </List>
);

export const FooterMenu: FC<FooterMenuProps> = ({
  children,
  className,
  link,
  location,
  nav,
  showNavLinks = false,
  showSocialMedia = true,
  ...rest
}) => {
  const { socialMedia, subscribeURL } = useSiteMetadataQuery();

  return (
    <Wrapper
      as="div"
      {...(rest as WrapperProps)}
      className={clsx(
        "flex-full md:flex-1",
        "items-center md:items-end",
        "mb-4 md:mb-0",
        className
      )}
    >
      {showSocialMedia && (
        <FooterMenuNav {...nav}>
          {socialMedia?.instagram && (
            <ButtonLink
              aria-label="Linked In"
              is="icon"
              button="icon"
              {...(link as ButtonLinkProps)}
              className={clsx("hover:text-brand-linkedin", link?.className)}
              rel="noopener"
              target="_blank"
              to={`https://www.linkedin.com/company/${socialMedia.linkedin}`}
            >
              <RiLinkedinBoxFill className="w-8 h-8 md:w-6 md:h-6 xl:w-7 xl:h-7" />
            </ButtonLink>
          )}
          {socialMedia?.linkedin && (
            <ButtonLink
              aria-label="Instagram"
              is="icon"
              button="icon"
              {...(link as ButtonLinkProps)}
              className={clsx("hover:text-brand-instagram", link?.className)}
              rel="noopener"
              target="_blank"
              to={`https://www.instagram.com/${socialMedia.instagram}`}
            >
              <RiInstagramFill className="w-7 h-7 md:w-5 md:h-5 xl:w-6 xl:h-6" />
            </ButtonLink>
          )}
          {/* {socialMedia?.twitter && (
            <ButtonLink
              aria-label="Twitter"
              is="icon"
              button="icon"
              {...link as ButtonLinkProps}
              className={clsx("hover:text-brand-twitter", link?.className)}
              rel="noopener"
              target="_blank"
              to={`https://twitter.com/${socialMedia.twitter}`}
            >
              <RiTwitterFill />
            </ButtonLink>
          )} */}
          {subscribeURL && (
            <ButtonLink
              aria-label="Mailchimp"
              is="icon"
              button="icon"
              {...(link as ButtonLinkProps)}
              className={clsx("hover:text-brand-mailchimp", link?.className)}
              rel="noopener"
              target="_blank"
              to={subscribeURL}
            >
              <SiMailchimp className="w-7 h-7 md:w-5 md:h-5 xl:w-6 xl:h-6" />
            </ButtonLink>
          )}
        </FooterMenuNav>
      )}
      {children}
      {showNavLinks && (
        <FooterMenuNav>
          <Link
            activeClassName="underline"
            {...(link as LinkProps)}
            className={clsx(
              "uppercase no-underline hover:underline",
              link?.className
            )}
            to="/services"
          >
            Services
          </Link>
          <Link
            activeClassName="underline"
            {...(link as LinkProps)}
            className={clsx(
              "uppercase no-underline hover:underline",
              link?.className
            )}
            to="/about"
          >
            About
          </Link>
          <Link
            activeClassName="underline"
            {...(link as LinkProps)}
            className={clsx(
              "uppercase no-underline hover:underline",
              link?.className
            )}
            to="/contact"
          >
            Contact
          </Link>
        </FooterMenuNav>
      )}
    </Wrapper>
  );
};
