import React, { FC } from "react";

import {
  FormField,
  FormFieldProps,
  SelectInput,
  SelectInputProps,
  SelectChange,
  SelectOption,
} from "components";

export interface SelectFieldProps
  extends Omit<FormFieldProps, "name" | "onChange"> {
  name: string;
  select?: SelectInputProps;
  options?: readonly SelectOption[];
  onChange?: SelectChange;
}

export const SelectField: FC<SelectFieldProps> = ({
  children,
  id,
  name,
  options,
  select,
  onChange,
  ...rest
}) => (
  <FormField {...(rest as FormFieldProps)} name={name}>
    <SelectInput
      id={id || name}
      name={name}
      options={options}
      onChange={onChange}
      {...select}
    />
    {children}
  </FormField>
);
