import React, { FC } from "react";
import clsx from "clsx";

import { TextPrimitive as Text, TextProps } from "components";
import { BrandColor } from "containers";
import { useBrandQuery } from "graphql";

import { brandName, brandNameTitle } from "./BrandName.module.scss";

export interface BrandNameProps extends Omit<TextProps, "color"> {
  color?: BrandColor;
  isShort?: boolean;
}

export const BrandName: FC<BrandNameProps> = ({
  children,
  className,
  color,
  isShort,
  ...rest
}) => {
  const { acronym, name } = useBrandQuery();

  return (
    <Text
      is="caption"
      className={clsx(
        brandName,
        color === "black" && "text-pepper",
        color === "white" && "text-salt",
        className
      )}
      {...(rest as TextProps)}
    >
      {children ? (
        children
      ) : isShort ? (
        acronym ? (
          acronym
        ) : (
          name
        )
      ) : (
        <>
          {name}
          <span className={brandNameTitle}>Architectural Design</span>
        </>
      )}
    </Text>
  );
};
