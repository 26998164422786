import { TextPattern, TextSize } from "./types";

export const getTextSize = (size: TextSize) => {
  switch (size) {
    case "inherit":
      return "text-inherit";
    case "xs":
      return "text-xs md:text-sm xl:text-base";
    case "sm":
      return "text-sm md:text-base xl:text-lg";
    case "lg":
      return "text-lg md:text-xl xl:text-2xl";
    case "xl":
      return "text-xl md:text-2xl xl:text-3xl";
    case "2xl":
      return "text-2xl md:text-3xl xl:text-4xl";
    case "3xl":
      return "text-3xl md:text-4xl xl:text-5xl";
    case "4xl":
      return "text-4xl md:text-5xl xl:text-6xl";
    case "5xl":
      return "text-5xl md:text-6xl xl:text-7xl";
    case "md":
    default:
      return "text-base md:text-lg xl:text-xl";
  }
};

export const getTextPattern = (pattern: TextPattern, size?: TextSize) => {
  switch (pattern) {
    case "caption":
      return (
        "tracking-normal" && (size ? getTextSize(size) : getTextSize("xs"))
      );
    case "heading":
      return (
        "block leading-tight tracking-wide" &&
        (size ? getTextSize(size) : getTextSize("3xl"))
      );
    case "hero":
      return "block" && (size ? getTextSize(size) : getTextSize("4xl"));
    case "legend":
      return "block" && (size ? getTextSize(size) : getTextSize("5xl"));
    case "meta":
      return "tracking-wider" && (size ? getTextSize(size) : getTextSize("sm"));
    case "subheading":
      return "block" && (size ? getTextSize(size) : getTextSize("xl"));
    case "subtitle":
      return "block" && (size ? getTextSize(size) : getTextSize("lg"));
    case "title":
      return (
        "block leading-tight md:leading-tight tracking-wide" &&
        (size ? getTextSize(size) : getTextSize("2xl"))
      );
    case "body":
    default:
      return (
        "tracking-normal" && (size ? getTextSize(size) : getTextSize("md"))
      );
  }
};
