import React, { FC, useState } from "react";
import CookieConsent, {
  Cookies,
  CookieConsentProps,
} from "react-cookie-consent";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";

import { Link, Text, Wrapper } from "components";
import { GatsbyLocation } from "types";
import { trackEvent } from "utils";

import {
  cookieConsentAcceptButton,
  cookieConsentButton,
  cookieConsentButtonWrapper,
  cookieConsentContainer,
  cookieConsentContent,
  cookieConsentDeclineButton,
  cookieConsentWrapper,
} from "./CookieConsentBanner.module.scss";
import clsx from "clsx";

export interface CookieConsentBannerProps extends CookieConsentProps {
  locationTracking?: GatsbyLocation;
}

export const CookieConsentBanner: FC<CookieConsentBannerProps> = ({
  buttonText = "Accept Cookies",
  children,
  cookieName = "CookieConsent",
  declineButtonText = "Decline",
  debug = false,
  disableStyles = true,
  enableDeclineButton = false,
  flipButtons = true,
  location = "bottom",
  locationTracking,
  ...rest
}) => {
  const [hidden, setHidden] = useState(false);

  const hideWrapper = () => {
    setHidden(true);
  };

  // TODO:
  // - store hidden state as cookie
  // - effect to hide wrapper
  // - add expiration to cookie matching cookie consent

  if (hidden) return null;

  return (
    <Wrapper as="aside" className={cookieConsentWrapper}>
      <CookieConsent
        buttonClasses={clsx(
          "transition-colors duration-250 ease-in-out",
          cookieConsentButton,
          cookieConsentAcceptButton
        )}
        buttonText={buttonText}
        buttonWrapperClasses={cookieConsentButtonWrapper}
        containerClasses={clsx("container", cookieConsentContainer)}
        contentClasses={cookieConsentContent}
        cookieName={cookieName}
        disableStyles={disableStyles}
        declineButtonClasses={clsx(
          "transition-colors duration-250 ease-in-out",
          cookieConsentButton,
          cookieConsentDeclineButton
        )}
        declineButtonText={declineButtonText}
        enableDeclineButton={enableDeclineButton}
        flipButtons={flipButtons}
        location={location}
        debug={debug}
        onAccept={() => {
          Cookies.set("gatsby-gdpr-google-analytics", "true");
          Cookies.set("gatsby-gdpr-facebook-pixel", "true");
          initializeAndTrack(locationTracking);
          trackEvent(
            cookieName,
            "Accept",
            "User agreed to site-wide cookie tracking."
          );
          hideWrapper();
        }}
        onDecline={() => {
          trackEvent(
            cookieName,
            "Decline",
            "User declined site-wide cookie tracking."
          );
          hideWrapper();
        }}
        {...rest}
      >
        {children ? (
          children
        ) : (
          <>
            <Text is="caption">
              We use cookies to improve your online experience. Please read our{" "}
              <Link size="inherit" to="/privacy-policy">
                Privacy Policy
              </Link>{" "}
              and{" "}
              <Link size="inherit" to="/cookie-policy">
                Cookie Policy
              </Link>{" "}
              for information about which cookies we use and what information we
              collect on our site. By clicking accept, you agree that we may
              store and access cookies on your device.
            </Text>
          </>
        )}
      </CookieConsent>
    </Wrapper>
  );
};
