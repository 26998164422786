import React, { FC } from "react";
import Scrollspy from "react-scrollspy";
import clsx from "clsx";

import { ListPrimitive as List, ListItem, ListProps } from "components";
import { HeaderMenuLink, HeaderMenuProps } from "containers";

import {
  activeServiceHeaderMenuItem,
  serviceHeaderMenuItem,
  serviceHeaderMenuLink,
  serviceHeaderMenuList,
} from "./ServiceHeaderMenu.module.scss";

export const ServiceHeaderMenu: FC<HeaderMenuProps> = ({
  children,
  className,
  link,
  location,
  showNavLinks = true,
  ...rest
}) => (
  <List
    as="nav"
    is="inline"
    {...(rest as ListProps)}
    className={clsx(serviceHeaderMenuList, className)}
  >
    {showNavLinks && (
      <Scrollspy
        items={["process", "contact"]}
        currentClassName={activeServiceHeaderMenuItem}
      >
        <ListItem className={serviceHeaderMenuItem}>
          <HeaderMenuLink
            className={serviceHeaderMenuLink}
            color="inherit"
            {...link}
            to="#process"
          >
            process
          </HeaderMenuLink>
        </ListItem>
        <ListItem className={serviceHeaderMenuItem}>
          <HeaderMenuLink
            className={serviceHeaderMenuLink}
            color="inherit"
            {...link}
            to="#contact"
          >
            contact
          </HeaderMenuLink>
        </ListItem>
        <ListItem className={serviceHeaderMenuItem}>
          <HeaderMenuLink
            className={serviceHeaderMenuLink}
            color="inherit"
            {...link}
            to="/news"
          >
            news
          </HeaderMenuLink>
        </ListItem>
      </Scrollspy>
    )}
    {children}
  </List>
);
