import React, { FC } from "react";
import clsx from "clsx";

import { Box, BoxProps, Link } from "components";

import {
  BrandLogo,
  BrandLogoProps,
  BrandName,
  BrandNameProps,
} from "./components";
import { BrandColor, BrandPattern } from "./types";

export interface BrandProps extends Omit<BoxProps, "color" | "name"> {
  color?: BrandColor;
  is?: BrandPattern;
  isShort?: boolean;
  linkTo?: string;
  logo?: BrandLogoProps;
  name?: BrandNameProps;
  showLink?: boolean;
  showLogo?: boolean;
  showName?: boolean;
}

export const Brand: FC<BrandProps> = ({
  children,
  className,
  color = "white",
  is,
  isShort = false,
  linkTo = "/",
  logo,
  name,
  showLink = true,
  showLogo = true,
  showName = false,
}) => {
  const brandClassName = clsx(
    "relative -top-0.5",
    "inline-flex flex-none content-center items-center",
    className
  );

  const renderBrand = () => (
    <>
      {showLogo && <BrandLogo is={is} color={color} {...logo} />}
      {showName && (
        <BrandName color={color} isShort={isShort} {...name}>
          {children}
        </BrandName>
      )}
    </>
  );

  return (
    <>
      {showLink ? (
        <Link className={brandClassName} to={linkTo}>
          {renderBrand()}
        </Link>
      ) : (
        <Box className={brandClassName}>{renderBrand()}</Box>
      )}
    </>
  );
};
